import { graphql } from "react-apollo";

import TranslationsQuery from "./TranslationsQuery.gql";

export default (propsToTranslations) => {
  return graphql(TranslationsQuery, {
    name: "translations",
    options: (props) => {
      return {
        variables: {
          ids: propsToTranslations(props),
        },
      };
    },
    props: ({ translations }) => {
      return {
        translationsLoading: translations.loading,
        translations: translations.translations,
      };
    },
  });
};
