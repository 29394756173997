import React from "react";

const Logo = () => (
  <svg
    width="72"
    height="26"
    className="logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 450 170"
    preserveAspectRatio="none"
  >
    <g>
      <g>
        <path d="m24.23,143.366c1.028,4.577 4.342,6.526 8.772,5.538c5.572,-1.27 5.935,-5.706 5.512,-8.926l14.593,-3.294c1.372,6.01 0.484,12.065 -2.379,16.784c-2.958,4.88 -7.703,8.523 -14.418,10.035c-8.886,2.004 -16.549,-0.638 -21.416,-6.15c-2.843,-3.279 -4.732,-8.268 -6.029,-14.129l-5.424,-23.916c-1.324,-5.874 -1.768,-11.178 -0.605,-15.365c2.017,-7.064 7.804,-12.745 16.684,-14.741c13.464,-3.045 23.956,4.813 27.001,18.27l-14.606,3.301c-1.324,-5.868 -4.672,-7.979 -9.095,-6.978c-4.45,1.009 -6.513,4.497 -5.283,9.922l6.693,29.649z" />
        <path d="m54.263,107.814c-1.331,-5.848 -1.761,-11.171 -0.591,-15.346c2.003,-7.078 7.804,-12.744 16.663,-14.761c8.886,-1.996 16.549,0.639 21.415,6.164c2.844,3.273 4.719,8.254 6.043,14.136l5.411,23.916c1.311,5.868 1.768,11.172 0.599,15.353c-2.017,7.078 -7.817,12.744 -16.677,14.74c-8.887,2.017 -16.556,-0.625 -21.409,-6.15c-2.843,-3.253 -4.718,-8.254 -6.036,-14.115l-5.418,-23.937zm20.528,22.922c1.23,5.424 4.592,7.683 9.027,6.681c4.437,-1.008 6.514,-4.483 5.29,-9.907l-6.433,-28.507c-1.229,-5.432 -4.604,-7.69 -9.034,-6.675c-4.442,1.001 -6.513,4.47 -5.283,9.921l6.433,28.487z" />
        <path d="m95.541,42.076l14.613,-3.287l18.397,81.461c1.224,5.444 3.159,6.661 8.866,5.969l3.092,13.739c-13.457,3.045 -22.524,1.485 -26.343,-15.406l-18.625,-82.476z" />
        <path d="m122.18,36.067l14.593,-3.294l18.397,81.468c1.23,5.431 3.146,6.654 8.886,5.955l3.105,13.746c-13.47,3.031 -22.544,1.479 -26.369,-15.406l-18.612,-82.469z" />
        <path d="m179.059,98.008l2.205,9.867c1.042,4.598 4.349,6.554 8.792,5.56c5.586,-1.271 5.922,-5.707 5.519,-8.927l14.579,-3.308c1.372,6.023 0.491,12.1 -2.359,16.791c-2.978,4.887 -7.716,8.53 -14.438,10.05c-8.872,1.996 -16.549,-0.646 -21.408,-6.157c-2.85,-3.268 -4.726,-8.269 -6.057,-14.13l-5.384,-23.916c-1.331,-5.867 -1.788,-11.178 -0.625,-15.365c2.023,-7.085 7.811,-12.731 16.684,-14.741c13.464,-3.045 23.989,4.961 26.873,17.698l4.537,20.045l-28.918,6.533zm-5.438,-74.007l15.467,-3.502l-14.345,24.46l-9.45,2.131l8.328,-23.089zm2.836,62.553l14.305,-3.22l-1.876,-8.328c-1.229,-5.424 -4.584,-7.669 -9.034,-6.675c-4.422,1.002 -6.492,4.47 -5.263,9.915l1.868,8.308z" />
        <path d="m242.162,124.989c0.921,3.394 4.329,6.533 9.337,5.391c4.732,-1.049 6.009,-4.8 4.954,-9.377l-3.22,-14.317l-0.303,0.061c-2.077,5.444 -6.057,9.188 -11.904,10.52c-5.868,1.317 -10.66,0.147 -14.311,-4.006c-2.42,-2.75 -4.141,-7.024 -5.955,-15.051l-6.245,-27.626c-2.339,-10.445 -2.85,-14.693 -1.674,-18.128c1.553,-4.417 4.531,-7.945 11.549,-9.519c7.145,-1.627 10.754,1.015 15.412,4.315l-1.479,-6.567l14.606,-3.293l18.384,81.447c2.245,9.881 -1.606,22.807 -17.073,26.281c-8.879,2.017 -21.059,-0.651 -26.665,-16.838l14.587,-3.293zm-6.957,-30.793c1.042,4.585 3.529,8.234 9.108,6.964c4.141,-0.928 6.561,-4.927 5.525,-9.518l-6.372,-28.198c-1.19,-5.296 -4.9,-8.382 -9.351,-7.373c-5.001,1.149 -6.237,5.64 -4.927,11.507l6.017,26.618z" />
        <path d="m258.314,5.329l14.62,-3.307l3.267,14.6l-14.579,3.293l-3.308,-14.586zm6.635,29.347l14.6,-3.294l16.408,72.581l-14.6,3.287l-16.408,-72.574z" />
        <path d="m314.334,67.457l2.218,9.868c1.063,4.591 4.336,6.547 8.792,5.545c5.579,-1.271 5.929,-5.706 5.519,-8.919l14.586,-3.287c1.352,6.009 0.464,12.065 -2.386,16.764c-2.944,4.9 -7.696,8.53 -14.418,10.042c-8.873,2.01 -16.556,-0.625 -21.409,-6.15c-2.85,-3.267 -4.726,-8.254 -6.05,-14.136l-5.41,-23.896c-1.311,-5.875 -1.775,-11.198 -0.605,-15.353c2.023,-7.092 7.811,-12.758 16.697,-14.761c13.45,-3.045 23.982,4.968 26.859,17.705l4.524,20.051l-28.917,6.527zm-2.602,-11.453l14.351,-3.24l-1.896,-8.308c-1.229,-5.432 -4.611,-7.684 -9.027,-6.675c-4.436,1.001 -6.52,4.477 -5.283,9.914l1.855,8.309z" />
        <path d="m344.541,16.688l14.62,-3.294l1.586,7.024l0.27,-0.081c1.976,-5.263 5.242,-9.444 11.978,-10.977c7.293,-1.646 16.105,1.331 19.036,14.358l12.563,55.703l-14.586,3.287l-11.353,-50.252c-1.203,-5.303 -4.497,-8.012 -9.095,-6.977c-5.444,1.23 -6.507,5.223 -5.034,11.676l11.03,48.819l-14.62,3.301l-16.395,-72.587z" />
      </g>
      <g>
        <path d="m278.15,113.838l6.474,-1.466c4.906,-1.116 8.328,1.23 9.443,6.144l3.22,14.264c1.271,5.64 -1.028,9.007 -6.163,10.163l-6.097,1.385l-6.877,-30.49zm10.332,25.388l1.996,-0.464c2.46,-0.545 3.119,-1.949 2.534,-4.577l-3.321,-14.681c-0.53,-2.393 -1.673,-3.583 -4.221,-2.998l-2.017,0.457l5.029,22.263z" />
        <path d="m304.93,130.158l0.666,2.943c0.303,1.365 1.29,1.956 2.628,1.667c1.674,-0.383 1.774,-1.728 1.627,-2.682l4.376,-0.968c0.41,1.781 0.161,3.596 -0.706,5.008c-0.888,1.465 -2.299,2.541 -4.315,2.998c-2.662,0.591 -4.961,-0.182 -6.406,-1.835c-0.847,-0.981 -1.411,-2.461 -1.814,-4.229l-1.593,-7.158c-0.403,-1.755 -0.545,-3.348 -0.188,-4.585c0.592,-2.131 2.319,-3.811 4.98,-4.409c4.014,-0.914 7.166,1.472 8.033,5.297l1.364,5.996l-8.652,1.957zm-0.786,-3.429l4.282,-0.968l-0.565,-2.487c-0.349,-1.626 -1.371,-2.299 -2.688,-1.996c-1.324,0.303 -1.956,1.338 -1.586,2.965l0.557,2.486z" />
        <path d="m313.95,114.967l4.376,-0.988l0.438,1.983c0.833,-1.499 1.458,-2.683 3.656,-3.166c1.754,-0.41 3.199,-0.061 4.275,1.183c0.719,0.827 1.25,2.104 1.774,4.497l1.875,8.268c0.699,3.126 0.841,4.396 0.518,5.411c-0.478,1.331 -1.364,2.379 -3.462,2.856c-1.714,0.39 -3.273,-0.114 -4.537,-1.311l-0.08,0.027l2.433,10.741l-4.355,0.988l-6.911,-30.489zm10.305,5.101c-0.403,-1.768 -1.317,-2.803 -2.823,-2.474c-1.331,0.303 -1.936,1.606 -1.56,3.2l1.896,8.415c0.31,1.392 1.479,2.151 2.723,1.889c1.674,-0.396 1.862,-1.707 1.56,-3.058l-1.796,-7.972z" />
        <path d="m348.574,129.956l-4.383,0.988l-0.457,-2.084l-0.087,0.007c-0.585,1.573 -1.566,2.823 -3.583,3.287c-2.164,0.484 -4.806,-0.39 -5.687,-4.295l-3.771,-16.657l4.375,-0.987l3.389,15.036c0.355,1.593 1.351,2.399 2.735,2.097c1.613,-0.376 1.936,-1.586 1.492,-3.488l-3.301,-14.613l4.376,-0.994l4.902,21.703z" />
        <path d="m345.287,98.68l4.369,-0.994l0.975,4.355l-4.355,0.988l-0.989,-4.349zm1.99,8.772l4.362,-0.995l4.899,21.704l-4.362,0.988l-4.899,-21.697z" />
        <path d="m364.538,109.932c-0.376,-1.438 -1.572,-1.976 -2.756,-1.721c-1.337,0.31 -1.875,1.56 -1.62,2.669c0.168,0.807 0.565,1.546 2.34,1.809l2.782,0.443c3.536,0.524 5.089,2.581 5.687,5.169c0.813,3.583 -1.264,7.172 -5.055,8.019c-3.407,0.773 -6.836,-0.847 -7.972,-5.081l4.12,-0.928c0.438,1.156 1.284,2.278 2.904,1.902c1.223,-0.282 2.231,-1.452 1.916,-2.904c-0.283,-1.196 -0.976,-1.896 -2.811,-2.157l-2.258,-0.322c-2.965,-0.384 -5.103,-2.064 -5.761,-4.968c-0.847,-3.69 1.472,-6.997 4.98,-7.804c3.569,-0.807 6.769,1.371 7.603,4.947l-4.099,0.927z" />
        <path d="m382.17,95.191l-3.657,4.194l-1.028,-4.624l3.637,-4.188l4.376,-0.987l6.876,30.482l-4.376,0.995l-5.828,-25.872z" />
        <path d="m402.415,104.366l-0.107,-0.087c-0.255,0.282 -0.8,0.604 -1.754,0.807c-1.533,0.343 -3.14,-0.195 -4.128,-1.143c-1.035,-1.016 -1.451,-2.057 -2.547,-6.896c-0.9,-3.973 -0.928,-4.867 -0.417,-6.433c0.686,-2.138 2.453,-3.65 4.86,-4.194c2.353,-0.531 4.57,0.134 6.144,1.714c1.169,1.183 1.519,1.996 2.426,5.969c0.848,3.771 0.848,4.691 0.599,6.453l-2.278,16.616l-4.88,1.102l2.082,-13.908zm-3.475,-5.262c0.302,1.324 1.513,1.97 2.628,1.707c1.123,-0.255 1.949,-1.344 1.646,-2.661l-1.27,-5.66c-0.316,-1.317 -1.513,-1.956 -2.643,-1.7c-1.108,0.248 -1.949,1.337 -1.653,2.668l1.292,5.646z" />
        <path d="m421.27,108.776l-8.678,1.976l-0.928,-4.113l1.352,-23.271l4.624,-1.042l-1.6,23.311l4.315,-0.975l-1.956,-8.691l4.355,-0.987l1.956,8.69l2.104,-0.463l0.921,4.1l-2.091,0.478l1.042,4.544l-4.39,0.968l-1.026,-4.525z" />
        <path d="m433.302,83.112l-4.8,1.082l0.794,3.516l-3.839,0.867l-1.733,-7.622l13.026,-2.931l0.975,4.355l-1.513,27.794l-4.617,1.049l1.707,-28.11z" />
      </g>
    </g>
  </svg>
);

export default Logo;
