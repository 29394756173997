import Button, { makeButtonClasses } from "./Button";
import PrimaryButton from "./PrimaryButton";
import LinkButton from "./LinkButton";
import SubmitButton from "./SubmitButton";
import WarningButton from "./WarningButton";
import IconButton from "./IconButton";
import RoundIconButton from "./RoundIconButton";

export default Button;

export {
  PrimaryButton,
  LinkButton,
  SubmitButton,
  WarningButton,
  IconButton,
  RoundIconButton,
  makeButtonClasses,
};
