import React from "react";
import { FormattedMessage } from "react-intl";
import Body, { BodyParagraph } from "theme/components/atoms/Typography/Body";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="not-found">
      <div>
        <h1>
          <FormattedMessage
            id="modules.PageError.NotFound.title"
            defaultMessage="404 : page not found "
          />
        </h1>
        <BodyParagraph>
          <Body>
            <FormattedMessage
              id="modules.PageError.NotFound.message1"
              defaultMessage="Unfortunately, the searched page does not correspond to any active page of our site."
            />
          </Body>
        </BodyParagraph>
        <BodyParagraph>
          <Body>
            <FormattedMessage
              id="modules.PageError.NotFound.message2"
              defaultMessage="Verify the adress or go back to {link}."
              values={{
                link: (
                  <Link to="/">
                    <FormattedMessage
                      id="modules.PageError.NotFound.linkHomepage"
                      defaultMessage="Front-Commerce homepage"
                    />
                  </Link>
                ),
              }}
            />
          </Body>
        </BodyParagraph>
      </div>
    </div>
  );
};

export default NotFound;
