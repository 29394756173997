import React from "react";

const THEMES = {
  white: "white",
  grey: "grey",
};

const LayoutThemeContext = React.createContext({
  theme: THEMES.white,
  setTheme: () => {},
});

const LayoutThemeProvider = (props) => {
  const [theme, setTheme] = React.useState(THEMES.grey);

  return (
    <LayoutThemeContext.Provider value={{ theme, setTheme }}>
      {props.children}
    </LayoutThemeContext.Provider>
  );
};

const useThemeGrey = () => {
  const { setTheme } = React.useContext(LayoutThemeContext);
  React.useEffect(() => {
    setTheme(THEMES.grey);
    return () => setTheme(THEMES.white);
  });
};

export { LayoutThemeProvider, LayoutThemeContext, useThemeGrey, THEMES };
