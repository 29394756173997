import React from "react";
import { FormattedMessage } from "react-intl";
import { H2 } from "theme/components/atoms/Typography/Heading";
import { BodyParagraph } from "theme/components/atoms/Typography/Body";
import ErrorImage from "./error-img.png";
import { ButtonLink } from "theme/components/atoms/Typography/Link";
import { makeAbsoluteUrl } from "theme/modules/Core";
import Button from "theme/components/atoms/Button";

const DefaultFallbackComponent = ({ error }) => {
  return (
    <div className="error-component">
      <img src={ErrorImage} alt="error" />
      <div className="error-component__text">
        <H2>
          <FormattedMessage
            id="components.helpers.ErrorBoundary.DefaultFallbackComponent.title"
            defaultMessage="An error occured."
          />
        </H2>
        <BodyParagraph>
          <FormattedMessage
            id="components.helpers.ErrorBoundary.DefaultFallbackComponent.subtitle"
            defaultMessage="You can try to refresh this page. If the problem persists, contact us."
          />
        </BodyParagraph>
        {error && <BodyParagraph>{error.toString()}</BodyParagraph>}
        <Button appearance="primary" onClick={() => window.location.reload()}>
          <FormattedMessage
            id="components.helpers.ErrorBoundary.DefaultFallbackComponent.buttonRefresh"
            defaultMessage="Refresh page"
          />
        </Button>
        <ButtonLink buttonAppearance="default" to={makeAbsoluteUrl("/")}>
          <FormattedMessage
            id="components.helpers.ErrorBoundary.DefaultFallbackComponent.button"
            defaultMessage="Return to website"
          />
        </ButtonLink>
      </div>
    </div>
  );
};

export default DefaultFallbackComponent;
