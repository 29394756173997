import React from "react";
import { Helmet } from "react-helmet-async";
import { H1 } from "theme/components/atoms/Typography/Heading";
import Body, { BodyParagraph } from "theme/components/atoms/Typography/Body";
import Logo from "theme/components/atoms/Logo";

const Maintenance = () => {
  return (
    <div className="maintenance">
      <Helmet>
        <title>503 - Actuellement indisponible</title>
        <meta httpEquiv="refresh" content="5" />
      </Helmet>
      <div className="topbar">
        <div className="container">
          <Logo />
        </div>
      </div>

      <div className="wrapper">
        <div className="mdl-layout__content">
          <div className="container">
            <H1>Collégien est actuellement indisponible</H1>
            <BodyParagraph>
              <Body>
                Dans le cadre d'une opération de maintenance, la boutique
                Collégien est actuellement indisponible. Veuillez nous excuser
                pour le désagrément, nous vous invitons à revenir d'ici quelques
                dizaines de minutes.
              </Body>
            </BodyParagraph>
            <BodyParagraph>
              <Body>
                Nous vous prions de nous excuser pour la gêne occasionnée et
                nous vous remercions pour votre compréhension.
              </Body>
            </BodyParagraph>
          </div>
        </div>
      </div>
      <div className="footer-container">
        <BodyParagraph>
          {`© ${new Date().getFullYear()} Collégien. Tous droits réservés.`}
        </BodyParagraph>
      </div>
    </div>
  );
};

export default Maintenance;
