module.exports = {
  b2c_fr: {
    url: process.env.FRONT_COMMERCE_FR_URL,
    locale: "fr-FR",
    currency: "EUR",
    default_country_id: "FR",
    dataLayer_strore_lang: "FR",
    gender_filter_name: "genre",
    gender_filter_values: [
      { code: 299, label: "homme" },
      { code: 300, label: "femme" },
      { code: 301, label: "enfant" },
    ],
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/fr.json")),
  },
  b2c_de: {
    url: process.env.FRONT_COMMERCE_DE_URL,
    locale: "de-DE",
    currency: "EUR",
    default_country_id: "DE",
    dataLayer_strore_lang: "DE",
    gender_filter_name: "genre",
    gender_filter_values: [
      { code: 299, label: "männer" },
      { code: 300, label: "frauen" },
      { code: 301, label: "kinder" },
    ],
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/de.json")),
  },
  b2c_en: {
    url: process.env.FRONT_COMMERCE_EN_URL,
    locale: "en-GB",
    currency: "EUR",
    default_country_id: "GB",
    dataLayer_strore_lang: "EN",
    gender_filter_name: "genre",
    gender_filter_values: [
      { code: 299, label: "men" },
      { code: 300, label: "women" },
      { code: 301, label: "kids" },
    ],
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json")),
  },
  b2c_it: {
    url: process.env.FRONT_COMMERCE_IT_URL,
    gender_filter_name: "genre",
    gender_filter_values: [
      { code: 299, label: "uomo" },
      { code: 300, label: "donna" },
      { code: 301, label: "bambino" },
    ],
    locale: "it-IT",
    currency: "EUR",
    default_country_id: "IT",
    dataLayer_strore_lang: "IT",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/it.json")),
  },
};
