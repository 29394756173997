module.exports = {
  root_categories_path: "1/3/",
  default_image_url: "https://placehold.it/150x220",
  available_page_sizes: [15 * 2, 15 * 4],
  mapsKey: "AIzaSyBE3DX2vA8loT-ngqcUgPzd_ggbfPcNFJ4",
  color_attribute_id: "93",
  website_id: 14,
  tax: 1.2,
  cookieMaxAgeInMonths: 13,
  contentSecurityPolicy: {
    directives: {
      scriptSrc: [
        "api.lyra.com",
        "api.payzen.eu",
        "ajax.googleapis.com",
        "ws.colissimo.fr",
        "api.mapbox.com",
        "www.paypalobjects.com",
        "*.google.com",
        "*.google.fr",
        "*.googleadservices.com",
        "googleads.g.doubleclick.net",
        "connect.facebook.net",
        "sw-assets.ekomiapps.de",
        "static.payzen.eu",
        "*.paypal.com",
        "secure.payzen.eu",
        "www.google-analytics.com",
        "maps.googleapis.com",
        "*.googletagmanager.com",
        "www.clarity.ms",
        "*.axept.io",
        "sibautomation.com",
      ],
      frameSrc: [
        "api.lyra.com",
        "ws.colissimo.fr",
        "admin.v2019.collegien.dis-hosting.fr",
        "www.facebook.com",
        "static.payzen.eu",
        "*.paypal.com",
        "www.paypalobjects.com",
        "secure.payzen.eu",
        "*.doubleclick.net",
        "sibautomation.com",
      ],
      styleSrc: [
        "api.lyra.com",
        "api.payzen.eu",
        "ws.colissimo.fr",
        "api.mapbox.com",
        "widgets.ekomi.com",
        "sw-assets.ekomiapps.de",
        "static.payzen.eu",
        "secure.payzen.eu",
        "fonts.googleapis.com",
        "www.googletagmanager.com",
      ],
      fontSrc: [
        "ws.colissimo.fr",
        "sw-assets.ekomiapps.de",
        "smart-widget-assets.ekomiapps.de",
        "fonts.gstatic.com",
        "github.com",
        "www.googletagmanager.com",
      ],
      imgSrc: [
        "api.lyra.com",
        "*.front-commerce.com",
        "collegien-shop.fr",
        "ws.colissimo.fr",
        "api.mapbox.com",
        "*.onyourmap.com",
        "stats.g.doubleclick.net",
        "*.paypal.com",
        "*.instagram.com",
        "*.cdninstagram.com",
        "*.google.com",
        "*.google.fr",
        "googleads.g.doubleclick.net",
        "www.facebook.com",
        "scontent.cdninstagram.com",
        "scontent-cdt1-1.cdninstagram.com",
        "sw-assets.ekomiapps.de",
        "smart-widget-assets.ekomiapps.de",
        "cx.atdmt.com",
        "static.payzen.eu",
        "www.paypalobjects.com",
        "secure.payzen.eu",
        "*.google-analytics.com",
        "maps.googleapis.com",
        "maps.gstatic.com",
        "*.googletagmanager.com",
        "*.google.com",
        "c.clarity.ms",
        "axeptio.imgix.net",
        "*.axept.io",
        "*.gstatic.com",
        "favicons.axept.io",
      ],
      connectSrc: [
        "ws.colissimo.fr",
        "widgets.ekomi.com",
        "smart-widget-assets.ekomiapps.de",
        "*.google-analytics.com",
        "stats.g.doubleclick.net",
        "maps.googleapis.com",
        "*.paypal.com",
        "www.paypalobjects.com",
        "www.google.fr",
        "*.analytics.google.com",
        "*.googletagmanager.com",
        "*.g.doubleclick.net",
        "*.google.com",
        "*.clarity.ms",
        "*.axept.io",
        "*.axept.eu",
        "in-automate.brevo.com",
      ],
      baseUri: [],
    },
    reportOnlyDirectives: {
      frameSrc: true,
    },
  },
  homepage_identifier: "home",
  defaultTitle: "Collégien  Chaussons Chaussettes pour toute la famille",
  defaultDescription:
    "Des chaussons design, doux et confortables grâce à leurs semelles souples et aérées !",
  search: {
    dynamicFacetSize: 10,
    ignoredAttributeKeys: [
      "description",
      "name",
      "image",
      "status",
      "tax_class_id",
    ],
    authorizedCategoriesFacet: [],
  },
  phoneNumber: "05 61 40 54 92",
  email: "contact@front-commerce.com",
  rewrittenToRoot: ["/home"],
  useUserTitles: false,
  giftCardFields: [
    { id: 1, name: "am_giftcard_amount_custom", type: "text" },
    { id: 2, name: "am_giftcard_sender_name", type: "text" },
    { id: 4, name: "am_giftcard_recipient_name", type: "text" },
    { id: 6, name: "am_giftcard_message", type: "textarea", optional: true },
    { id: 7, name: "am_giftcard_email_checkbox", type: "radio" },
    { id: 3, name: "am_giftcard_sender_email", type: "email" },
    {
      id: 5,
      name: "am_giftcard_recipient_email",
      type: "email",
      value_from: "am_giftcard_sender_email",
    },
  ],
};
