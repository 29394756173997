import { graphql } from "react-apollo";

import MediasQuery from "./MediasQuery.gql";

export default (propsToMedias) => {
  return graphql(MediasQuery, {
    name: "medias",
    options: (props) => {
      return {
        variables: {
          ids: propsToMedias(props),
        },
      };
    },
    props: ({ medias }) => {
      return {
        mediasLoading: medias.loading,
        medias: medias.medias,
      };
    },
  });
};
