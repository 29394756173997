import React from "react";
import Icon from "../Icon";
import Button from "./Button";

const RoundIconButton = ({ icon, iconSize = "small", title, ...props }) => {
  return (
    <Button appearance="round" {...props}>
      <Icon icon={icon} size={iconSize} title={title} />
    </Button>
  );
};

export default RoundIconButton;
