import React from "react";
import Button from "./Button";

export default (props) => {
  const buttonProps = {
    appearance: "primary",
    ...props,
  };
  return <Button type="submit" {...buttonProps} />;
};
