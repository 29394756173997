import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Component = ({ as: BaseComponent, ...rest }) => {
  return <BaseComponent {...rest} />;
};

/** @typedef {PropTypes.InferProps<typeof propTypes>} BodyProps */
/** @type {React.FunctionComponent<BodyProps>} */
const Body = ({ children, raw, type, ...rest }) => {
  const className = classNames("body", {
    "body--fade": type === "fade",
  });
  const as = ["span", "em", "strong", "p"].indexOf(type) > -1 ? type : "span";

  if (raw && typeof children === "string") {
    return (
      <Component
        {...rest}
        as={as}
        className={className}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    );
  } else {
    return (
      <Component {...rest} as={as} className={className}>
        {children}
      </Component>
    );
  }
};

const propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(["span", "em", "strong", "fade", "p"]),
  raw: PropTypes.bool,
};
Body.propTypes = propTypes;

export default Body;
