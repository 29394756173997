import { graphql } from "react-apollo";

import LinksForQuery from "./LinksForQuery.gql";
import { splitLinkInfo } from "theme/utils/extractFromListHelper";
import { compose } from "recompose";

export default (propsToLinks) => {
  return compose(
    graphql(LinksForQuery, {
      name: "links",
      options: (props) => {
        const splittedLinks = propsToLinks(props).map(splitLinkInfo);

        const variables = splittedLinks.reduce(
          (acc, link) => {
            if (link.type === "product") {
              acc["productIds"].push(link.id);
            } else if (link.type === "category") {
              acc["categoryIds"].push(link.id);
            } else if (link.type === "customlink") {
              acc["customLinks"].push(parseInt(link.id));
            }

            return acc;
          },
          {
            productIds: [],
            categoryIds: [],
            customLinks: [],
          }
        );

        return {
          variables,
        };
      },
      props: ({ links }) => {
        return {
          linksLoading: links.loading,
          links: links.productLinks &&
            links.categoryLinks &&
            links.customLinks && [
              ...links.categoryLinks,
              ...links.productLinks,
              ...links.customLinks,
            ],
        };
      },
    })
  );
};
