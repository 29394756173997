import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import deprecateSplittedBooleanEnumProperty from "web/core/dx/deprecateSplittedBooleanEnumProperty";

const STATE_DISABLED = "disabled";
const STATE_PENDING = "pending";

export const makeButtonClasses = (appearance, size = "default", state) =>
  classNames("button", {
    [`button--${appearance}`]: appearance,
    [`button--${size}`]: size,
    [`button--${state}`]: state,
  });

const Button = ({
  onClick,
  onDisableClick,
  children,
  appearance,
  primary,
  thirdly,
  ghost,
  icon,
  link,
  warning,
  size,
  state,
  disabled,
  pending,
  dangerouslyIgnoreOnClick,
  type,
  title,
}) => {
  if (!appearance) {
    appearance = deprecateSplittedBooleanEnumProperty("Button", "appearance", [
      {
        oldName: "primary",
        oldValue: primary,
        newValue: "primary",
      },
      {
        oldName: "thirdly",
        oldValue: thirdly,
        newValue: "thirdly",
      },
      {
        oldName: "ghost",
        oldValue: ghost,
        newValue: "ghost",
      },
      {
        oldName: "icon",
        oldValue: icon,
        newValue: "icon",
      },
      {
        oldName: "link",
        oldValue: link,
        newValue: "link",
      },
      {
        oldName: "warning",
        oldValue: warning,
        newValue: "warning",
      },
    ]);
  }

  if (!state) {
    state = deprecateSplittedBooleanEnumProperty("Button", "state", [
      {
        oldName: "pending",
        oldValue: pending,
        newValue: "pending",
      },
      {
        oldName: "disabled",
        oldValue: disabled,
        newValue: "disabled",
      },
    ]);
  }

  if (
    process.env.NODE_ENV !== "production" &&
    state === STATE_DISABLED &&
    !onDisableClick
  ) {
    console.warn(
      "You should tell the user why the button is disabled if he still click on it"
    );
  }

  if (
    process.env.NODE_ENV === "development" &&
    typeof onClick === "function" &&
    type === "submit"
  ) {
    console.warn("You can't use `onClick` on a submit button");
  }

  if (
    process.env.NODE_ENV === "development" &&
    state !== STATE_DISABLED &&
    typeof onClick !== "function" &&
    type !== "submit" &&
    !dangerouslyIgnoreOnClick
  ) {
    console.warn("You must use `onClick` on a button");
  }

  return (
    <button
      onClick={
        state === STATE_DISABLED
          ? (e) => {
              e.preventDefault();
              onDisableClick && onDisableClick();
            }
          : onClick
      }
      className={makeButtonClasses(appearance, size, state)}
      type={type}
      title={title}
      disabled={
        (state === STATE_DISABLED || state === STATE_PENDING) && !onDisableClick
      }
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  onDisableClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  thirdly: PropTypes.bool,
  size: PropTypes.oneOf(["default", "small", "big"]),
  appearance: PropTypes.oneOf([
    "default",
    "ghost",
    "thirdly",
    "primary",
    "link",
    "link-reverse",
    "warning",
    "icon",
    "round",
  ]),
  state: PropTypes.oneOf(["default", STATE_PENDING, STATE_DISABLED]),
  dangerouslyIgnoreOnClick: PropTypes.bool,
  type: PropTypes.string,
};

export default Button;
