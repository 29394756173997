const fieldForIdInList =
  (list) =>
  (id, field, fallback = null) => {
    if (!list) {
      return fallback;
    }

    const item = list.find((item) => item && item.id === id);

    return item ? item[field] : fallback;
  };

const fieldForIdInLinkList =
  (list) =>
  (id, field, fallback = null) => {
    if (!list) {
      return fallback;
    }
    if (!fallback) {
      fallback = "/";
    }

    const item = list.find((item) => {
      if (item && `${item.type}_${item.identifier}` === id) {
        return true;
      }
      if (
        item.__typename === "Translation" &&
        (`${item.type}_${item.identifier}` === id ||
          `customlink_${item.identifier}` === id ||
          `customlink_${item.id}` === id)
      ) {
        field = "value";
        return true;
      }
      return false;
    });

    return item ? item[field] : fallback;
  };

const splitLinkInfo = (info) => {
  if (!info) {
    return false;
  }
  const type = info.substr(0, info.indexOf("_"));
  const id = info.substr(info.indexOf("_") + 1);

  return {
    type,
    id,
  };
};

export { fieldForIdInList, fieldForIdInLinkList, splitLinkInfo };
