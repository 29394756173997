import React from "react";
import PropTypes from "prop-types";
import invariant from "fbjs/lib/invariant";
import classnames from "classnames";
import attention from "./icons/Attention.svg?inline";
import cart_full from "./icons/Cart Full.svg?inline";
import chevron from "./icons/chevron.svg?inline";
import chevronUp from "./icons/chevron.svg?inline";
import chevronLeft from "./icons/chevron.svg?inline";
import chevronRight from "./icons/chevron.svg?inline";
import chevronDown from "./icons/chevron.svg?inline";
import delivery from "./icons/Delivery.svg?inline";
import facebook from "./icons/Facebook.svg?inline";
import locker from "./icons/Locker.svg?inline";
import play from "./icons/Play.svg?inline";
import question from "./icons/Question.svg?inline";
import star_off from "./icons/Star_Off.svg?inline";
import twitter from "./icons/Twitter.svg?inline";
import burger_menu from "./icons/Burger Menu.svg?inline";
import cart from "./icons/Cart.svg?inline";
import coloris from "./icons/Coloris.svg?inline";
import eye_off from "./icons/Eye_Off.svg?inline";
import heart from "./icons/Heart.svg?inline";
import ok from "./icons/OK.svg?inline";
import enveloppe from "./icons/enveloppe.svg?inline";
import plus from "./icons/Plus.svg?inline";
import refresh from "./icons/Refresh.svg?inline";
import star_on from "./icons/Star_On.svg?inline";
import user from "./icons/User.svg?inline";
import calendar from "./icons/Calendar.svg?inline";
import check from "./icons/check.svg?inline";
import cross from "./icons/Cross.svg?inline";
import eye_on from "./icons/Eye_On.svg?inline";
import instagram from "./icons/Instagram.svg?inline";
import phone from "./icons/Phone.svg?inline";
import pro from "./icons/Pro.svg?inline";
import search from "./icons/Search.svg?inline";
import start from "./icons/Start.svg?inline";
import zoom_in from "./icons/Zoom in.svg?inline";
import loader from "./icons/loader.svg?inline";
import gift from "./icons/gift.svg?inline";
import country_en from "./icons/Flag-en.svg?inline";
import country_fr from "./icons/Flag-fr.svg?inline";
import country_de from "./icons/Flag-de.svg?inline";
import country_it from "./icons/Flag-it.svg?inline";
import download from "./icons/download.svg?inline";

//override default FC icons checkmark
import checkmarkCircle from "./icons/check.svg?inline";
export const whitelist = [
  "loader",
  "home",
  "user",
  "chevron-right",
  "chevron-left",
  "chevron-up",
  "chevron-down",
  "chevron-right-circle",
  "chevron-left-circle",
  "chevron-up-circle",
  "chevron-down-circle",
  "plus-circle",
  "circle-minus",
  "cross-circle",
  "exit",
  "chevron-up",
  "phone-handset",
  "checkmark-circle",
  "store",
  "lock",
  "calendar-full",
  "credit-card",
  "sort-amount-asc",
  "shirt",
  "smile",
  "cog",
  "eye",
  "pencil",
  "trash",
  "envelope",
  "camera",
  "magnifier",
  "sync",
  "menu",
  "list",
  "redo",
  "heart",
  "attention",
  "cart_full",
  "chevron",
  "delivery",
  "facebook",
  "enveloppe",
  "locker",
  "play",
  "question",
  "star_off",
  "twitter",
  "burger_menu",
  "cart",
  "coloris",
  "eye_off",
  "heart",
  "ok",
  "plus",
  "refresh",
  "star_on",
  "calendar",
  "check",
  "cross",
  "eye_on",
  "instagram",
  "phone",
  "pro",
  "search",
  "start",
  "zoom_in",
  "size",
  "gift",
  "country_fr",
  "country_it",
  "country_de",
  "country_en",
  "download",
  "notification-off",
  "notification",
].sort();
const svgIcons = {
  attention,
  cart_full,
  chevron,
  chevronUp,
  chevronLeft,
  chevronRight,
  chevronDown,
  delivery,
  facebook,
  locker,
  play,
  question,
  star_off,
  twitter,
  burger_menu,
  cart,
  coloris,
  eye_off,
  heart,
  ok,
  enveloppe,
  plus,
  refresh,
  star_on,
  user,
  calendar,
  check,
  cross,
  eye_on,
  instagram,
  phone,
  pro,
  search,
  start,
  zoom_in,
  loader,
  gift,
  country_fr,
  country_it,
  country_de,
  country_en,
  download,
  //override default FC icons checkmark
  checkmarkCircle,
};
const Icon = ({ icon, size, title }) => {
  invariant(
    whitelist.indexOf(icon) > -1,
    `"${icon}" is not a whitelisted icon`
  );
  if (process.env.NODE_ENV === "development" && title === undefined) {
    console.warn(
      "You must set a title (it can be an empty string if you don't want one) to use <Icon />."
    );
  }
  if (svgIcons[icon]) {
    const className = classnames("icon", `icon--${icon}`, {
      [`icon--${size}`]: size,
    });
    return (
      <span
        className={className}
        dangerouslySetInnerHTML={{ __html: svgIcons[icon] }}
      />
    );
  } else {
    const className = classnames("lnr", `lnr-${icon}`, {
      [`lnr--${size}`]: size,
    });
    return <span title={title} className={className} />;
  }
};
Icon.defaultProps = {
  size: "default",
};
Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["default", "huge", "small", "mini", "tiny"]),
  title: PropTypes.string.isRequired,
};
export default Icon;
