import React from "react";
import { Helmet } from "react-helmet-async";
import { H1 } from "theme/components/atoms/Typography/Heading";
import Body, { BodyParagraph } from "theme/components/atoms/Typography/Body";
import Logo from "theme/components/atoms/Logo";

const Offline = () => {
  return (
    <div className="maintenance">
      <Helmet>
        <title>Vous êtes hors ligne - Collégien</title>
        <meta httpEquiv="refresh" content="5" />
      </Helmet>
      <div className="topbar">
        <div className="container">
          <Logo />
        </div>
      </div>

      <div className="wrapper">
        <div className="mdl-layout__content">
          <div className="container">
            <H1>
              Vous ne pouvez pas accéder à Collégien car vous êtes hors-ligne.
            </H1>
            <BodyParagraph>
              <Body>
                Il semble que vous ayez des problèmes avec votre réseau.
                Veuillez vous assurer que vous avez accès à Internet avant de
                recharger la page.
              </Body>
            </BodyParagraph>
          </div>
        </div>
      </div>
      <div className="footer-container">
        <BodyParagraph>
          {`© ${new Date().getFullYear()} Collégien. Tous droits réservés.`}
        </BodyParagraph>
      </div>
    </div>
  );
};

export default Offline;
