import React from "react";
import classNames from "classnames";
import Icon from "theme/components/atoms/Icon";

const Spinner = (props) => (
  <span
    className={classNames("spinner", { "spinner--fullpage": props.isFullPage })}
    style={props.dimensions}
  >
    <span className="spinner__loading">
      <Icon icon="loader" title="loader" />
    </span>
  </span>
);

export default Spinner;
